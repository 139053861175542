@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-muni {
  background-color: #8BC756;
}


/* .botonHeader:focus{
    color: red
} */


.botonHeader:before {
 
  content: "";
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: bottom right;
  background: currentColor;
  transition: transform 0.25s ease-out;
}

.botonHeader:hover:before {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.iconoRecursos{
  color: #8BC756 !important;
 
}